import { useNavigate } from "react-router-dom";
import "./Header.css";
export const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="logo-container">
        <img
          onClick={() => navigate("/")}
          alt=""
          style={{ cursor: "pointer" }}
          className="logo"
          src="logo.png"
        />
        <a style={{ cursor: "pointer" }} className="title" href="/">
          Bala Rent Car
        </a>
      </div>
      <div className="menu">
        <a style={{ cursor: "pointer" }} href="/">
          Home
        </a>
        <img
          onClick={() => {
            window.open(
              `https://wa.me/+355693028888?text=Hello, I want to rent a car`,
              "_blank"
            );
          }}
          alt=""
          className="whatsapp"
          width={40}
          height={40}
          src={"./wp.svg"}
        />
      </div>
    </div>
  );
};
