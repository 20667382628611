import { Carousel } from "react-responsive-carousel";
import { Header } from "../components/Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css";
import { Navigate, useParams } from "react-router-dom";
import { cars } from "./Main";
import { RWebShare } from "react-web-share";

export const SingleCar = () => {
  const param = useParams();
  const car = cars.find(({ id }) => id.toString() === param.id);
  if (!car) return <Navigate to="/" />;
  return (
    <div>
      <Header />
      <div className="body-container">
        <Carousel>
          {car.images.map((image, index) => {
            return (
              <div key={index}>
                <img alt="" className="carusel-image" src={`./${image}`} />
              </div>
            );
          })}
        </Carousel>
        <div className="details-container">
          {car?.brand && (
            <div className="attr-container">
              <span className="attr-title">Brand:</span>
              <span className="attr-value">{car.brand}</span>
            </div>
          )}
          {car?.name && (
            <div className="attr-container">
              <span className="attr-title">Model:</span>
              <span className="attr-value">{car.name}</span>
            </div>
          )}
          {car?.fuel && (
            <div className="attr-container">
              <span className="attr-title">Fuel:</span>
              <span className="attr-value">{car.fuel}</span>
            </div>
          )}
          {car?.transmission && (
            <div className="attr-container">
              <span className="attr-title">Transmission:</span>
              <span className="attr-value">{car.transmission}</span>
            </div>
          )}
          {car?.doors && (
            <div className="attr-container">
              <span className="attr-title">Doors:</span>
              <span className="attr-value">{car.doors}</span>
            </div>
          )}
          {car?.sea && (
            <div className="attr-container">
              <span className="attr-title">Motor size(cc):</span>
              <span className="attr-value"> {car.size}</span>
            </div>
          )}
          {car?.seats && (
            <div className="attr-container">
              <span className="attr-title">Seats:</span>
              <span className="attr-value">{car.seats}</span>
            </div>
          )}
          <div className="attr-container">
            <span className="attr-title">Whatsapp:</span>
            <img
              onClick={() => {
                window.open(
                  `https://wa.me/+355693028888?text=Hello, I am interested renting this car www.balarent.com/${param.id} `,
                  "_blank"
                );
              }}
              alt=""
              className="whatsapp"
              width={40}
              height={40}
              src={"./wp.svg"}
            />
          </div>
          <div className="attr-container">
            <span className="attr-title">Phone:</span>
            <a
              style={{ textDecoration: "none", color: "unset" }}
              className="attr-value"
              href="tel:355693028888"
            >
              +355693028888
            </a>
          </div>
          <div className="attr-container">
            <span className="attr-title">Share:</span>
            <RWebShare
              data={{
                text: "Look at this car that i found!!!",
                url: `www.balarent.com/${param.id}`,
                title: "Bala rent car Albania",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <img
                alt=""
                style={{ cursor: "pointer" }}
                width={30}
                height={30}
                src="./share.svg"
              />
            </RWebShare>
          </div>
        </div>
      </div>
    </div>
  );
};
