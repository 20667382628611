import { Header } from "../components/Header/Header";
import "./style.css";

export const cars = [
  {
    id: 1,
    brand: "Wolkswagen",
    name: "Golf VI",
    mainImage: "golf6Blu.png",
    images: [
      "golf6Blu1.jpeg",
      "golf6Blu2.jpeg",
      "golf6Blu3.jpeg",
      "golf6Blu4.jpeg",
      "golf6Blu5.jpeg",
      "golf6Blu6.jpeg",
    ],
    fuel: "Diesel",
    transmission: "Manual",
    doors: 4,
    size: "1600cc",
    seats: 5,
  },
  {
    id: 2,
    name: "Golf VI",
    brand: "Wolkswagen",
    mainImage: "golf6Zi.png",
    images: [
      "golf6Zi1.jpeg",
      "golf6Zi2.jpeg",
      "golf6Zi3.jpeg",
      "golf6Zi4.jpeg",
      "golf6Zi5.jpeg",
    ],
    fuel: "Diesel",
    transmission: "Manual",
    doors: 4,
    size: "1998cc",
    seats: 5,
  },
  {
    id: 3,
    name: "Fiesta",
    brand: "Ford",
    mainImage: "ford1.png",
    images: [
      "ford1.jpeg",
      "ford2.jpeg",
      "ford3.jpeg",
      "ford4.jpeg",
      "ford5.jpeg",
      "ford6.jpeg",
    ],
    fuel: "Diesel",
    transmission: "Manual",
    doors: 4,
    size: "1600cc",
    seats: 5,
  },
  {
    id: 4,
    brand: "Hyundai",
    name: "Avante",
    mainImage: "avante.png",
    images: [
      "avante1.jpeg",
      "avante2.jpeg",
      "avante3.jpeg",
      "avante4.jpeg",
      "avante5.jpeg",
    ],
    fuel: "Diesel",
    transmission: "Automatic",
    doors: 4,
    size: "1600cc",
    seats: 5,
  },
  {
    id: 5,
    name: "Golf V",
    brand: "Wolkswagen",
    mainImage: "golf5bardh.png",
    images: [
      "golf5bardh1.jpeg",
      "golf5bardh2.jpeg",
      "golf5bardh3.jpeg",
      "golf5bardh4.jpeg",
    ],
    fuel: "Diesel",
    transmission: "Manual",
    doors: 4,
    size: "1998cc",
    seats: 5,
  },
];

export const Main = () => {
  return (
    <div>
      <Header />
      <div className="body">
        <span style={{ fontSize: 40 }}>Catalog</span>
        <div className="card-container">
          {cars.map((car) => (
            <div key={car.id} className="card">
              <img className="car" alt="" src={`./${car.mainImage}`} />
              <div className="car-desc">
                <span className="name">{car.name}</span>
                <span className="desc">{car.fuel}</span>
                <span className="desc">{car.transmission}</span>
                <a
                  style={{ cursor: "pointer" }}
                  href={`/${car.id}`}
                  className="button"
                >
                  View
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
